import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

import { RepositoryService } from '../../services/repository/repository.service';
import { ExperimentSurvey } from "../../models/experimentSurvey";
import { Subject } from "rxjs/Rx";
import DateTimeUtils from "../../utilities/datetimeutils";
import Utils from "../../utilities/utils";

@Component({
  selector: 'app-experiment-surveys-list',
  templateUrl: './experiment-surveys-list.component.html',
  styleUrls: ['./experiment-surveys-list.component.css']
})
export class ExperimentSurveysListComponent implements OnInit, OnChanges {

  @Input() experimentId: number;
  @Input() newExperimentSurveySubject: Subject<ExperimentSurvey>;

  public surveys: ExperimentSurvey[];

  constructor(private repositoryService: RepositoryService) { }

  ngOnInit() {
    this.refreshData();
    this.newExperimentSurveySubject.subscribe(r => {
      if (this.experimentId == r.experimentId) {
        this.surveys.push(r);
        this.sortData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.experimentId.currentValue != changes.experimentId.previousValue)
      this.refreshData();
  }

  refreshData() {
    if (!this.experimentId) {
      this.surveys = [];
      return;
    }
    this.repositoryService.getExperimentSurveys(this.experimentId).subscribe(res => {
      this.surveys = res;
      this.sortData();
    });
  }

  sortData() {
    this.surveys.sort((a, b) => a.id > b.id ? -1 : 1);
  }

  deleteSurvey(surveyId) {
    if (window.confirm('Are sure you want to delete this item?')) {
      this.repositoryService.deleteExperimentSurvey(this.experimentId, surveyId).subscribe(res => {
        var idx = -1;
        for (var i = 0; i < this.surveys.length; i++)
          if (this.surveys[i].id == surveyId)
            idx = i;
        this.surveys.splice(idx, 1);
      });
    }
  }

  onDownloadResults(surveyId) {
    this.repositoryService.getSurveyResults_asReport(this.experimentId, surveyId).subscribe(blob => {
      Utils.downloadAsFile(blob, "surveyresults_" + surveyId + "_" + DateTimeUtils.NowUnixTime() + ".csv");
    });
  }

  onDeleteResults(surveyId) {
    if (window.confirm("Are you sure you want to delete all responses?")) {
      this.repositoryService.deleteSurveyResults(this.experimentId, surveyId).subscribe(res => {
        this.refreshData();
      });
    }
  }
}
