import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { Experiment } from '../../models/experiment';
import { Role } from '../../models/role';
import { Account } from '../../models/account';
import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.css']
})
export class AccountsListComponent implements OnInit, OnChanges {

  @Input() newAccountSubject: Subject<Account>;

  public accounts: Account[];
  private experiments: Map<number, Experiment>;

  constructor(private repositoryService: RepositoryService) { }

  ngOnChanges() {
  }

  ngOnInit() {
    this.retrieveData();
    this.newAccountSubject.subscribe(r => {
      this.accounts.push(r);
    });
  }

  retrieveData() {
    this.repositoryService.getExperiments().subscribe(res => {
      this.experiments = new Map<number, Experiment>();
      for (var i = 0; i < res.length; i++)
        this.experiments.set(res[i].id,res[i]);

      this.repositoryService.getAccounts().subscribe(res => {
        this.accounts = res;

        // Add experiments and role summaries
        for (var i = 0; i < this.accounts.length; i++) {
          this.accounts[i]["rolesSummary"] = this.accounts[i].roles.map(r => r.name).join(",");
          this.accounts[i]["experimentCodesSummary"] = this.accounts[i].experiments
            .map(eid => this.experiments.has(eid) ? this.experiments.get(eid).code : "").join(",");
        }
      });
    })
  }

  deleteAccount(accountId: number) {
    if(window.confirm('Are sure you want to delete this item?')){
      this.repositoryService.deleteAccount(accountId).subscribe(res => {
        var idx = -1;
        for (var i = 0; i < this.accounts.length; i++)
          if (this.accounts[i].id == accountId)
            idx = i;
        this.accounts.splice(idx, 1);
      });
    }
  }
}
