import { Occurence } from "./occurence";

export class DataSource {
  id: number;
  type: string;
  occurence: Occurence;
  duration: number;
  experimentId: number;
  properties: any;

  constructor(id: number, experimentId: number, type: string,
    occurence: Occurence, duration: number, properties: any) {
    this.id = id;
    this.experimentId = experimentId;
    this.type = type;
    this.occurence = occurence;
    this.duration = duration;
    this.properties = properties;
  }
}
