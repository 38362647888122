import { Component, forwardRef, OnInit, OnChanges, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Occurence } from "../../models/occurence";
import { UserEventsService } from '../../services/userevents/userevents.service';
import { BehaviorSubject } from 'rxjs';

export const TRIGGERINPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TriggerInputComponent),
  multi: true
};

@Component({
  selector: 'app-trigger-input',
  templateUrl: './trigger-input.component.html',
  styleUrls: ['./trigger-input.component.css'],
  providers: [TRIGGERINPUT_VALUE_ACCESSOR]
})
export class TriggerInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  private hasForcedRequery = false;

  private occurence: Occurence = null;

  private userEventChoices = [];

  @Input() experimentId: number = null;

  constructor(private userEventsService: UserEventsService) {
  }

  ngOnChanges() {
    this.update();
  }

  ngOnInit(): void {
    this.update();
  }

  update() {
    if (!this.experimentId) {
      this.userEventChoices.length = 0;
      return;
    }
    this.userEventsService.getUserEventChoices(this.experimentId, !this.hasForcedRequery).then(uec => {
      this.hasForcedRequery = true;
      this.userEventChoices = uec;
    }).then(() => {
      this.updateSelectedChoice();
    });
  }

  updateSelectedChoice() {
    if (!this.experimentId)
      this.evtChoice = null;
    else
      this.userEventsService.getEventChoiceFromOccurence(
        this.experimentId, this.occurence).then(d => {
          this.evtChoice = d;
          this.onDataChanged();
        });
  }

  evtChoice: any = null;

  // Control value accessor functions
  writeValue(obj: any): void {
    this.occurence = obj || new Occurence(0, null, null, null);
    this.updateSelectedChoice();
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
  }

  onDataChanged() {
    // Update occurence
    this.occurence.eventType = this.evtChoice ? this.evtChoice.eventType : null;
    this.occurence.eventFilter = this.evtChoice ? this.evtChoice.eventFilter : null;

    if (this.onChange)
        this.onChange(this.occurence);
  }
}
