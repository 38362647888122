import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { Experiment } from "../../models/experiment";
import { Account } from "../../models/account";
import { Role } from "../../models/role";
import { Tracker } from "../../models/tracker";
import { ExperimentSurvey } from "../../models/experimentSurvey";
import { Notification } from "../../models/notification";
import "rxjs/Rx";
import { Subject } from "rxjs/Rx";
import { DataSource } from "../../models/dataSource";
import AppSettings from "../../app-settings";
import { SessionInfo } from "../session/sessionInfo";
import { SessionService } from "../session/session.service";

@Injectable()
export class RepositoryService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  private url = AppSettings.backendUrl;

  // ******** Authentication ********

  login(userName: string, password: string): Observable<SessionInfo> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/auth/login", { userName: userName, password: password });
    });
  }

  logout(token: string): Observable<string> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post<string>(this.url + "/auth/logout", token);
    });
  }

  changePassword(userName: string, oldPassword: string, newPassword: string): Observable<Account> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/auth/changePassword", {
          userName: userName,
          secret: oldPassword,
          newSecret: newPassword
        });
    });
  }

  // ******** Accounts ********

  getRoles(): Observable<Role[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Role[]>(this.url + "/roles")
    });
  }

  getAccounts(): Observable<Account[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Account[]>(this.url + "/accounts")
    });
  }

  getAccount(id: number): Observable<Account> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Account>(this.url + "/accounts/" + id)
    });
  }

  createAccount(account: Account): Observable<Account> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/accounts", account)
    });
  }

  updateAccount(account: Account): Observable<Account> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/accounts/" + account.id, account)
    });
  }

  deleteAccount(id: number): Observable<Account> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .delete(this.url + "/accounts/" + id)
    });
  }

  // ******** Experiments ********

  getExperiments(): Observable<Experiment[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Experiment[]>(this.url + "/trackingExperiments")
    });
  }

  getExperiment(id: number): Observable<Experiment> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Experiment>(this.url + "/trackingExperiments/" + id)
    });
  }

  createExperiment(experiment: Experiment): Observable<Experiment> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/trackingExperiments", experiment)
    });
  }

  updateExperiment(experiment: Experiment): Observable<Experiment> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/trackingExperiments/" + experiment.id, experiment)
    });
  }

  deleteExperiment(id: number): Observable<Experiment> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .delete(this.url + "/trackingExperiments/" + id)
    });
  }

  cleanExperimentData(experiment: Experiment): Observable<boolean> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/trackingExperiments/cleanData/" + experiment.id, [])
    });

  }

  // ******** Trackers ********

  getTrackers(experimentId: number): Observable<Tracker[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Tracker[]>(this.url + "/trackers/" + experimentId.toString())
    });
  }

  // ******** Experiment surveys ********

  getExperimentSurveys(experimentId: number): Observable<ExperimentSurvey[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<ExperimentSurvey[]>(this.url + "/surveys/" + experimentId)
    });
  }

  getExperimentSurvey(experimentId: number, id: number): Observable<ExperimentSurvey> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<ExperimentSurvey>(this.url + "/surveys/" + experimentId + "/" + id)
    });
  }

  createExperimentSurvey(experimentId: number, experimentSurvey: ExperimentSurvey): Observable<ExperimentSurvey> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/surveys/" + experimentId, experimentSurvey)
      });
  }

  updateExperimentSurvey(experimentId: number, experimentSurvey: ExperimentSurvey): Observable<ExperimentSurvey> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/surveys/" + experimentId + "/" + experimentSurvey.id, experimentSurvey)
    });
  }

  deleteExperimentSurvey(experimentId: number, id: number): Observable<ExperimentSurvey> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .delete(this.url + "/surveys/" + experimentId + "/" + id)
    });
  }

  deleteSurveyResults(experimentId: number, surveyId: number): any {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/surveyresults/" + experimentId + "/deleteForSurvey/" + surveyId, []);
    });
  }

  // ******** Data sources ********

  getDataSources(experimentId: number): Observable<DataSource[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<DataSource[]>(this.url + "/datacollectionsources/" + experimentId)
    });
  }

  getDataSource(experimentId: number, id: number): Observable<DataSource> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<DataSource>(this.url + "/datacollectionsources/" + experimentId + "/" + id)
    });
  }

  createDataSource(experimentId: number, dataSource: DataSource): Observable<DataSource> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/datacollectionsources/" + experimentId, dataSource)
    });
  }

  updateDataSource(experimentId: number, dataSource: DataSource): Observable<DataSource> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/datacollectionsources/" + experimentId + "/" + dataSource.id, dataSource)
    });
  }

  deleteDataSource(experimentId: number, id: number): Observable<DataSource> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .delete(this.url + "/datacollectionsources/" + experimentId + "/" + id)
    });
  }

  deleteDataValues(experimentId: number, dataSourceId: number): any {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/dataValues/" + experimentId + "/deleteForDataSource/" + dataSourceId, []);
    });
  }

  // ******** User Events ********
  getUserEventTypes(experimentId: number): Observable<String[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<String[]>(this.url + "/userEvents/" + experimentId + "/types");
    });
  }

  deleteUserEventsForType(experimentId: number, eventType: string): any {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/userEvents/" + experimentId + "/deleteAllForExperimentAndType/" + eventType, []);
    });
  }

  // ******** Experiment notifications ********

  getNotifications(experimentId: number): Observable<Notification[]> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Notification[]>(this.url + "/notifications/" + experimentId)
    });
  }

  getNotification(experimentId: number, id: number): Observable<Notification> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get<Notification>(this.url + "/notifications/" + experimentId + "/" + id)
    });
  }

  createNotification(experimentId: number, notification: Notification): Observable<Notification> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .post(this.url + "/notifications/" + experimentId, notification)
    });
  }

  updateNotification(experimentId: number, notification: Notification): Observable<Notification> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .put(this.url + "/notifications/" + experimentId + "/" + notification.id, notification)
    });
  }

  deleteNotification(experimentId: number, id: number): Observable<Notification> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .delete(this.url + "/notifications/" + experimentId + "/" + id)
    });
  }

  // ******** Reports ********
  getSurveyResults_asReport(experimentId: number, surveyId: number): Observable<any> {
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get(this.url + "/reports/experimentSurveyResults/" + experimentId + "/" + surveyId, { responseType: 'blob' })
    });
  }

  getUserEvents_asReport(experimentId: number, eventType: string, fromTime: number, tillTime: number): Observable<any> {
    let url: string = this.url + "/reports/userEvents/" + experimentId + "/" + eventType + "/" + fromTime + "/" + tillTime;
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get(url, { responseType: 'blob' })
    });
  }

  getDataValues_asCSV(experimentId: number, sourceId: number, fromTime: number, tillTime: number): Observable<any> {
    let url: string = this.url + "/reports/dataValues/" + experimentId + "/" + sourceId + "/" + fromTime + "/" + tillTime;
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get(url, { responseType: 'blob' })
    });
  }

  getDataValues_asGeoJson(experimentId: number, sourceId: number, fromTime: number, tillTime: number): Observable<any> {
    let url: string = this.url + "/reports/dataValues/" + experimentId + "/gpstrajectories/" + sourceId + "/" + fromTime + "/" + tillTime;
    return this.executeAndBlockNextRequests(() => {
      return this.http
        .get(url, { responseType: 'blob' })
    });
  }

  private handleError(error: Response) {
    console.log(error.statusText);
    return error;
  }

  private currentRequest: Observable<any> = null;

  private executeAndBlockNextRequests(fn: () => Observable<any>, s?: Subject<any>) {
    s = s || new Subject<any>();
    var self = this;

    // If there is currently a request running, reschedule after request finished
    if (self.currentRequest != null) {
      self.currentRequest.subscribe(
        () => {
          self.executeAndBlockNextRequests(fn, s)
      });
    } // If no request, execute and set request.
    else {
      self.currentRequest = s;
      fn()
        .catch((error: any) => {
          s.error(error);
          return null;
        })
        .finally(self.currentRequest = null)
        .subscribe(r => {
          s.next(r);
          s.complete();
        });
    }
    return s;
  }
}
