import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';

@Pipe({
  name: 'cronExpression'
})
export class CronExpressionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value)
      return "-";
    try {
      return cronstrue.toString(value);
    } catch (e) {
      return "Invalid cron expression";
    }
  }

}
