import { Component, OnInit } from '@angular/core';
import { SessionService } from './services/session/session.service';
import { SessionInfo } from './services/session/sessionInfo';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  public sessionInfo: SessionInfo;

  constructor(private sessionService: SessionService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.sessionInfo = this.sessionService.getSession();
  }

  logout() {
    this.router.navigateByUrl('login').then(() => {
      this.sessionService.storeSession(null);
      this.toastr.success("User successfully logged out.");
    })
  }
}
