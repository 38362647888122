import { Injectable } from '@angular/core';
import "rxjs/Rx";
import { RepositoryService } from '../repository/repository.service';
import { Occurence } from '../../models/occurence';

@Injectable()
export class UserEventsService {

  constructor(private repository: RepositoryService) { }

  private userEventChoices = {};
  
  public getUserEventChoices(experimentId: number, requery: boolean = false) {
    if (!requery && this.userEventChoices[experimentId])
      return Promise.resolve(this.userEventChoices[experimentId]);

    return this.updateUserEventChoices(experimentId);
  }

  public getEventChoiceFromOccurence(experimentId: number, occurence: Occurence,
    requery: boolean = false): Promise<any> {
    if (!experimentId)
      return Promise.resolve(null);
    if (!occurence)
      occurence = new Occurence(null, null, null, null);
    return this.getUserEventChoices(experimentId, requery).then(choices => {
      for (var i = 0; i < choices.length; i++) {
        let evt = choices[i];
        if (evt.eventType != occurence.eventType)
          continue;
        if ((!evt.eventFilter && !occurence.eventFilter) ||
          JSON.stringify(JSON.parse(evt.eventFilter)) == occurence.eventFilter) {
          return evt;
        }
      }
      return null;
    });
  }

  private updateUserEventChoices(experimentId: number) {
    let uec = [];
    uec.push({
        name: "-",
        eventType: null,
        eventFilter: null
      }
    );

    var p1 = this.repository.getExperimentSurveys(experimentId).toPromise().then(surveys => {
      for (var i = 0; i < surveys.length; i++) {
        uec.push({
          name: "Start survey " + surveys[i].code,
          eventType: "survey",
          eventFilter: JSON.stringify({ action: "start", survey_id: surveys[i].id })
        });
        uec.push({
          name: "Finish survey " + surveys[i].code,
          eventType: "survey",
          eventFilter: JSON.stringify({ action: "finish", survey_id: surveys[i].id })
        });
      }
    });

    var p2 = this.repository.getDataSources(experimentId).toPromise().then(datasources => {
      for (var i = 0; i < datasources.length; i++) {
        let datasourceDescription = datasources[i].type + " (" + datasources[i].id + ")";
        uec.push({
          name: "Start data source " + datasourceDescription,
          eventType: "datasource",
          eventFilter: JSON.stringify({ action: "start", datasource_id: datasources[i].id })
        });
        uec.push({
          name: "Stop data source " + datasourceDescription,
          eventType: "datasource",
          eventFilter: JSON.stringify({ action: "stop", datasource_id: datasources[i].id })
        });
      }
    });

    return Promise.all([p1, p2]).then(() => {
      this.userEventChoices[experimentId] = uec;
      return uec;
    });
  }
}
