import { Injectable, OnInit } from '@angular/core';
import "rxjs/Rx";
import { SessionInfo } from './sessionInfo';

@Injectable()
export class SessionService implements OnInit {

  private sessionInfo: SessionInfo = new SessionInfo("","",[]);
  private sessionChangeCallbacks = [];

  constructor() { }

  ngOnInit() {
    this.sessionInfo = this.getSession();
  }

  public registerOnSessionChange(fn) {
    this.sessionChangeCallbacks.push(fn);  
  }

  public unregisterOnSessionChange(fn) {
    var idx = this.sessionChangeCallbacks.findIndex(f => f == fn);
    if (idx < 0)
      return;
    this.sessionChangeCallbacks.splice(idx, 1);
  }

  // Copies properties from supplied sessionInfo into sessionInfo object member.
  public storeSession(updatedSessionInfo: SessionInfo) {
    if (updatedSessionInfo == null)
      updatedSessionInfo = new SessionInfo("", "", []);
    this.sessionInfo = Object.assign(this.sessionInfo, updatedSessionInfo);
    localStorage.setItem('currentaccount_info', JSON.stringify(this.sessionInfo));

    for (var i = 0; i < this.sessionChangeCallbacks.length; i++)
      this.sessionChangeCallbacks[i]();
  }

  // Returns sessionInfo object. This can be used as a singleton variable.
  public getSession(): SessionInfo {
    var item = localStorage.getItem('currentaccount_info');
    if (!item)
      return null;
    let result: SessionInfo = JSON.parse(item);
    this.sessionInfo = Object.assign(this.sessionInfo, result);
    return this.sessionInfo;
  }

  public hasAdminRole(): boolean {
    let session: SessionInfo = this.getSession();
    if (!session)
      return false;
    return !!session.roles.find(r => r.code == "admin");
  }
}
