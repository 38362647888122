import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'unixDate'
})
export class UnixDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value > 0)
      return moment(value * 1000).format('YYYY-MM-DD');
    return '-';
  }

}
