import { Component, OnInit, forwardRef, ViewChild, Input } from '@angular/core';
import { CronOptions } from 'cron-editor';
import cronstrue from 'cronstrue';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CRONINPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CronInputComponent),
  multi: true
};

@Component({
  selector: 'app-cron-input',
  templateUrl: './cron-input.component.html',
  styleUrls: ['./cron-input.component.css'],
  providers: [CRONINPUT_VALUE_ACCESSOR]
})
export class CronInputComponent implements OnInit, ControlValueAccessor {

  public cronExpression: string = "";
  public innerCron: string = null;
  public hasValue: boolean;
  public editorVisible: boolean;

  @ViewChild('expressioninput') expressionInput;
  @Input() required: boolean = false;

  private _cron: string;

  set cron(value: string) {
    this._cron = value;
    this.hasValue = this.cron != null;
    if (this.cron != null && this.cron.length > 0)
      this.innerCron = this.cron;
    if (!this.innerCron)
      this.innerCron = "0 0 * ? * * *"; // only to make cron-editor not give exception.
    this.checkCron();
  }
  get cron(): string { return this._cron; }

  public cronOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: "00:00:00",

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    use24HourTime: true,
    hideSeconds: true
  };

  constructor() { }

  onClickedOutsideEditor($event) {
    if ($event.srcElement == this.expressionInput.nativeElement)
      return;
    this.editorVisible = false;
  }

  onClickedInExpressionInput() {
    if (this.hasValue && !this.editorVisible)
      this.editorVisible = true;
    else
      this.editorVisible = false;
  }

  onHasValueChanged() {
    this.editorVisible = this.hasValue;
    this.onChanged();
  }

  onChanged() {
    this.checkCron();
    var newValue: string = this.hasValue ? this.innerCron : null;
    if (newValue != this.cron) {
      this.cron = newValue;
      if (this.onChange)
        this.onChange(newValue);
    }
  }

  ngOnInit() {
  }

  // Control value accessor functions
  writeValue(obj: any): void {
    this.cron = obj;
  }

  onChange = (_: any) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
  }

  // Helper functions
  private checkCron(): boolean {
    try {
      if (!this.hasValue) {
        this.cronExpression = "";
        return true;
      }
      if (this.innerCron == null || this.innerCron == '')
        return true;
      this.cronExpression = cronstrue.toString(this.innerCron);
    } catch (e) {
      this.cronExpression = "Invalid cron expression";
      return false;
    }
    return true;
  }
}
