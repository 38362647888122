import * as moment from 'moment-timezone';

export default class DateTimeUtils {

  public static NowUnixTime(): number {
    return (new Date()).getTime();
  }

  public static UnixTimeToDate(t: number, timezone: String): Date {
    if (!t || t == 0)
      return null;

    if (timezone) {
      var m = moment.tz(t, timezone);
      return new Date(m.year(), m.month(), m.date());
    }
    return new Date(t);
  }

  public static DateToUnixTime(date: Date, timezone: String): number {
    if (!date)
      return null;

    if (timezone)
      return moment.tz([date.getFullYear(), date.getMonth(), date.getDate()], timezone).valueOf();
    return date.getTime();
  }

  public static UnixTimeToDateTime(t: number, timezone: String): Date {
    if (!t || t == 0)
      return null;

    if (timezone) {
      var m = moment.tz(t, timezone);
      return new Date(m.year(), m.month(), m.date(), m.hours(), m.minutes(), m.seconds(), m.milliseconds());
    }
    return new Date(t);
  }

  public static DateTimeToUnixTime(date: Date, timezone: String): number {
    if (!date)
      return null;

    if (timezone)
      return moment.tz([date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()], timezone).valueOf();
    return date.getTime();
  }
};
