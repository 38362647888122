export class Experiment {
  id: number;
  code: string;
  name: string;
  tsStart: number;
  tsEnd: number;
  trackingCron: string;
  timezone: string;
  isdebug: boolean;
  isdiscoverable: boolean;
  welcomeText: string;
  helpText: string;
  
  constructor(id: number, code: string, name: string,
    tsStart: number, tsEnd: number, trackingCron: string,
    timezone: string, isdebug: boolean, isdiscoverable: boolean,
    welcomeText: string, helpText: string) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.tsStart = tsStart;
    this.tsEnd = tsEnd;
    this.trackingCron = trackingCron;
    this.timezone = timezone;
    this.isdebug = isdebug;
    this.isdiscoverable = isdiscoverable;
    this.welcomeText = welcomeText;
    this.helpText = helpText;
  }
}
