import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DataSource } from "../../models/dataSource";
import { RepositoryService } from "../../services/repository/repository.service";
import { Experiment } from "../../models/experiment";

@Component({
  selector: 'app-experiment-datasource-manage-form',
  templateUrl: './experiment-datasource-manage-form.component.html',
  styleUrls: ['./experiment-datasource-manage-form.component.css']
})
export class DataSourceManageFormComponent implements OnInit {

  public dataSourceForm: FormGroup;

  model: DataSource = <DataSource>{};
  experiment: Experiment = <Experiment>{};
  typeProperties: Map<string, object> = new Map<string, object>();

  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private route: ActivatedRoute) {

    this.typeProperties['gps'] = {};
    this.typeProperties['accelerometer'] = {};
    this.typeProperties['activity'] = {};
    this.typeProperties['bluetooth'] = {};
    this.typeProperties['event counts'] = {};
  }

  ngOnInit() {
    this.dataSourceForm = this.fb.group({
      type: new FormControl('', Validators.required),
      duration: new FormControl(''),
      occurence: new FormControl(''),
      gps_config: new FormControl(''),
      gps_begintrim: new FormControl(''),
      gps_endtrim: new FormControl(''),
      acc_speed: new FormControl(''),
      activity_interval: new FormControl(''),
      bluetooth_interval: new FormControl(''),
      eventcounts_types: new FormControl(''),
      eventcounts_name: new FormControl(''),
      eventcounts_description: new FormControl(''),
      eventcounts_includelocation: new FormControl(''),
      eventcounts_locationmaxagems: new FormControl('')
    });

    let experimentId = this.route.snapshot.params['experimentId'];
    let dataSourceId = this.route.snapshot.params['dataSourceId'];
    this.repository.getDataSource(experimentId, dataSourceId).subscribe(r => {
      this.model = r;

      if (this.model.properties != null && this.model.properties != "")
        Object.assign(this.typeProperties[this.model.type], JSON.parse(this.model.properties));
    });
    this.repository.getExperiment(experimentId).subscribe(r => {
      this.experiment = r;
    })

    this.dataSourceForm.valueChanges.subscribe(r => {
      this.scheduleUpdateDataSource();
    });
  }

  private updateTs = -1;
  scheduleUpdateDataSource() {
    this.updateTs = (new Date()).getTime();
    let localTs = this.updateTs;

    window.setTimeout(() => {
      if (localTs != this.updateTs)
        return;
      this.updateDataSource();
    }, 300);
  }

  private updateDataSource() {
    if (!this.dataSourceForm.valid)
      return;

    // Deep copy
    var modelToPersist = JSON.parse(JSON.stringify(this.model));
    // And then stringify the properties
    modelToPersist.properties = JSON.stringify(this.typeProperties[this.model.type]);
    this.repository.updateDataSource(modelToPersist.experimentId, modelToPersist).subscribe(
      r => { });
  }
}
