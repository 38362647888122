import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { RepositoryService } from './repository/repository.service';
import { PushMessagingService } from './pushmessaging/pushmessaging.service';
import { SessionService } from './session/session.service';
import { AuthInterceptor } from './authinterceptor/authinterceptor.service';
import { UserEventsService } from './userevents/userevents.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    RepositoryService,
    PushMessagingService,
    SessionService,
    UserEventsService,
    AuthInterceptor
  ]
})
export class ServicesModule { }
