import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { Experiment } from "../../models/experiment";
import "rxjs/Rx";
import AppSettings from "../../app-settings";

@Injectable()
export class PushMessagingService {

  constructor(private http: HttpClient) { }

  private url = AppSettings.backendUrl;

  sendMessageToExperiment(experiment: Experiment, message: string, updateExperimentConfig: boolean = true): Observable<any> {
    var notificationUrl = this.url + "/notifications/send/" + experiment.id + "/" + updateExperimentConfig;
    return this.http.post(notificationUrl, message);
  };
}
