import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../services/repository/repository.service';
import { SessionService } from '../services/session/session.service';
import { SessionInfo } from "../services/session/sessionInfo";
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public userName: string;
  public password: string;
  public errorMessage: string = "";

  constructor(private repository: RepositoryService,
    private sessionService: SessionService, private router: Router) { }

  ngOnInit() {
    window.setTimeout(() => {
      this.sessionService.storeSession(null);
    }, 0);
  }

  login() {
    this.repository.login(this.userName, this.password).subscribe(sessionInfo => {
      this.sessionService.storeSession(sessionInfo);
      this.router.navigateByUrl('experiments');
    }, (err: any) => {
      this.errorMessage = err.error.message;
    });
  }

}
