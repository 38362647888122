import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExperimentspageComponent } from './experimentspage/experimentspage.component';
import { AccountsPageComponent } from './accountspage/accountspage.component';
import { ExperimentManageFormComponent } from './forms/experiment-manage-form/experiment-manage-form.component';
import { AccountManageFormComponent } from './forms/account-manage-form/account-manage-form.component';
import { ExperimentSurveyManageFormComponent } from "./forms/experiment-survey-manage-form/experiment-survey-manage-form.component";
import { DataSourceManageFormComponent } from "./forms/experiment-datasource-manage-form/experiment-datasource-manage-form.component";
import { LoginComponent } from './login/login.component';
import { ChangePasswordFormComponent } from './forms/changepassword-form/changepassword-form.component';
import { ExperimentNotificationManageFormComponent } from './forms/experiment-notification-manage-form/experiment-notification-manage-form.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ExperimentspageComponent },
      { path: 'login', component: LoginComponent },
      { path: 'changepassword', component: ChangePasswordFormComponent },
      { path: 'experiments', component: ExperimentspageComponent },
      { path: 'experiments/manage/:id', component: ExperimentManageFormComponent },
      { path: 'accounts', component: AccountsPageComponent },
      { path: 'accounts/manage/:id', component: AccountManageFormComponent },
      { path: 'surveys/manage/:experimentId/:surveyId', component: ExperimentSurveyManageFormComponent },
      { path: 'notifications/manage/:experimentId/:notificationId', component: ExperimentNotificationManageFormComponent },
      { path: 'datasources/manage/:experimentId/:dataSourceId', component: DataSourceManageFormComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
