import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

import { RepositoryService } from '../../services/repository/repository.service';
import AppSettings from "../../app-settings";
import { Subject } from "rxjs/Rx";
import DateTimeUtils from "../../utilities/datetimeutils";
import Utils from "../../utilities/utils";

@Component({
  selector: 'app-experiment-userevents-list',
  templateUrl: './experiment-userevents-list.component.html',
  styleUrls: ['./experiment-userevents-list.component.css']
})
export class ExperimentUserEventsListComponent implements OnInit, OnChanges {

  @Input() experimentId: number;
 
  public userEventTypes: String[];

  constructor(private repositoryService: RepositoryService) { }

  ngOnInit() {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.experimentId.currentValue != changes.experimentId.previousValue)
      this.refreshData();
  }

  refreshData() {
    if (!this.experimentId) {
      this.userEventTypes = [];
      return;
    }
    this.repositoryService.getUserEventTypes(this.experimentId).subscribe(res => {
      this.userEventTypes = res;
      this.sortData();
    });
  }

  sortData() {
    this.userEventTypes.sort();
  }

  onDownloadResults(userEventType) {
    this.repositoryService.getUserEvents_asReport(this.experimentId, userEventType, 0, 0).subscribe(blob => {
      Utils.downloadAsFile(blob, "userevents_" + userEventType + "_" + DateTimeUtils.NowUnixTime() + ".csv");
    });
  }

  onDeleteResults(userEventType) {
    if (window.confirm("Are you sure you want to delete all user events?")) {
      this.repositoryService.deleteUserEventsForType(this.experimentId, userEventType).subscribe(res => {
        this.refreshData();
      });
    }
  }
}
