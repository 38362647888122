import { Occurence } from "./occurence";

export class Notification {
  id: number
  message: string;
  occurence: Occurence;
  experimentId: number;

  constructor(id: number, message: string,
    experimentId: number, occurence: Occurence) {
    this.id = id;
    this.message = message;
    this.experimentId = experimentId;
    this.occurence = occurence;
  }
}
