import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppBootstrapModule } from './app-bootstrap.module';
import { ServicesModule } from './services/services.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Installed modules.
import { BsDatepickerModule, TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ToastrModule } from 'ngx-toastr';
import { CronEditorModule } from 'cron-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TagInputModule } from 'ngx-chips'; 

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordFormComponent } from './forms/changepassword-form/changepassword-form.component';
import { ExperimentspageComponent } from './experimentspage/experimentspage.component';
import { AccountsPageComponent } from './accountspage/accountspage.component';
import { UserhomeComponent } from './userhome/userhome.component';
import { ExperimentsListComponent } from './lists/experiments-list/experiments-list.component';
import { AccountsListComponent } from './lists/accounts-list/accounts-list.component';
import { UnixDatePipe } from './services/pipes/unix-date.pipe';
import { ExperimentManageFormComponent } from './forms/experiment-manage-form/experiment-manage-form.component';
import { AccountManageFormComponent } from './forms/account-manage-form/account-manage-form.component';
import { SurveyEditorComponent } from './survey/survey-editor/survey-editor.component';
import { ExperimentSurveysListComponent } from './lists/experiment-surveys-list/experiment-surveys-list.component';
import { ExperimentSurveyManageFormComponent } from './forms/experiment-survey-manage-form/experiment-survey-manage-form.component';
import { ExperimentNotificationsListComponent } from './lists/experiment-notifications-list/experiment-notifications-list.component';
import { ExperimentNotificationManageFormComponent } from './forms/experiment-notification-manage-form/experiment-notification-manage-form.component';
import { DataSourcesListComponent } from './lists/experiment-datasources-list/experiment-datasources-list.component';
import { DataSourceManageFormComponent } from './forms/experiment-datasource-manage-form/experiment-datasource-manage-form.component';
import { CronInputComponent } from './components/croninput/cron-input.component';
import { ExperimentInfoPaneComponent } from './components/experiment-info-pane/experiment-info-pane.component';

import { ClickOutsideModule } from 'ng-click-outside';
import { AuthInterceptor } from './services/authinterceptor/authinterceptor.service';
import { NeedsAdminRoleDirective } from './directives/needs-admin-role.directive';
import { NeedsAuthenticatedDirective } from './directives/needs-authenticated.directive';
import { ExperimentUserEventsListComponent } from './lists/experiment-userevents-list/experiment-userevents-list.component';
import { TriggerInputComponent } from './components/triggerinput/trigger-input.component';
import { CronExpressionPipe } from './services/pipes/cronexpression.pipe';
import { UserEventExpressionPipe } from './services/pipes/usereventexpression.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordFormComponent,
    ExperimentspageComponent,
    AccountsPageComponent,
    UserhomeComponent,
    ExperimentsListComponent,
    AccountsListComponent,
    UnixDatePipe,
    CronExpressionPipe,
    UserEventExpressionPipe,
    ExperimentManageFormComponent,
    AccountManageFormComponent,
    SurveyEditorComponent,
    ExperimentSurveysListComponent,
    ExperimentSurveyManageFormComponent,
    ExperimentNotificationsListComponent,
    ExperimentNotificationManageFormComponent,
    DataSourcesListComponent,
    DataSourceManageFormComponent,
    CronInputComponent,
    ExperimentInfoPaneComponent,
    NeedsAdminRoleDirective,
    NeedsAuthenticatedDirective,
    ExperimentUserEventsListComponent,
    TriggerInputComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TagInputModule,
    AppRoutingModule,
    AppBootstrapModule,
    ServicesModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    CronEditorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
