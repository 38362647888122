import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { RepositoryService } from '../../services/repository/repository.service';
import { ActivatedRoute } from '@angular/router';
import { Account } from "../../models/account";
import { Role } from "../../models/role";
import { Experiment } from "../../models/experiment";

@Component({
  selector: 'app-account-manage-form',
  templateUrl: './account-manage-form.component.html',
  styleUrls: ['./account-manage-form.component.css']
})
export class AccountManageFormComponent implements OnInit, OnDestroy {

  public accountForm: FormGroup;
  
  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private route: ActivatedRoute) {
  }

  private dropdownsInitialized: boolean = false;
  model: Account = <Account>{};
  helpmodel = { selectedRoles: [], selectedExperiments: [] };
  experiments_all: Experiment[] = null;
  roles_all: Role[] = null;

  rolesDropdownSettings = {
    idField: "id",
    textField: "name"
  }

  experimentsDropdownSettings = {
    idField: "id",
    textField: "code",
    allowSearchFilter: true
  }

  ngOnInit() {
    this.accountForm = this.fb.group({
      userName: new FormControl('', Validators.required),
      password: new FormControl(''),
      roles: new FormControl(''),
      experiments: new FormControl('')
    });

    let id = this.route.snapshot.params['id'];

    this.repository.getRoles().subscribe(r => { this.roles_all = r; this.trySetSelectedRolesAndExperiments(); });
    this.repository.getExperiments().subscribe(r => { this.experiments_all = r; this.trySetSelectedRolesAndExperiments(); });

    
    this.repository.getAccount(id).subscribe(r => {
      this.model = r;
      this.trySetSelectedRolesAndExperiments();
      this.accountForm.valueChanges.subscribe(r => {
        this.scheduleUpdateAccount();
      });
    });
  }

  private trySetSelectedRolesAndExperiments() {
    if (this.experiments_all == null || this.roles_all == null || !this.model.id)
      return;

    this.helpmodel.selectedRoles = this.model.roles;
    let selectedExperiments: Experiment[] = [];
    let expMap = new Map<number, Experiment>(this.experiments_all.map(e => [e.id, e] as [number, Experiment]));
    for (var i = 0; i < this.model.experiments.length; i++) {
      var id = this.model.experiments[i];
      if (!expMap.has(id))
        return;
      selectedExperiments.push(expMap.get(id));
    }
    this.helpmodel.selectedExperiments = selectedExperiments;
    this.dropdownsInitialized = true;
  }

  ngOnDestroy() {
    this.updateAccount();
  }

  private updateTs = -1;
  private scheduleUpdateAccount() {
    this.updateTs = (new Date()).getTime();
    let localTs = this.updateTs;

    window.setTimeout(() => {
      if (localTs != this.updateTs)
        return;
      this.updateAccount();
    }, 300);
  }

  private updateAccount() {
    if (!this.accountForm.valid)
      return;

    if (this.dropdownsInitialized) {
      var self = this;
      this.model.roles = this.helpmodel.selectedRoles.map(r => self.roles_all.find(role => role.id == r.id));
      this.model.experiments = this.helpmodel.selectedExperiments.map(exp => exp.id);
    }
    return this.repository.updateAccount(this.model);
  }
}
