import { Component, OnChanges, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { PushMessagingService } from "../../services/pushmessaging/pushmessaging.service";
import { RepositoryService } from "../../services/repository/repository.service";
import { Experiment } from "../../models/experiment";
import { Tracker } from "../../models/tracker";

@Component({
  selector: 'app-experiment-info-pane',
  templateUrl: './experiment-info-pane.component.html',
  styleUrls: ['./experiment-info-pane.component.css']
})
export class ExperimentInfoPaneComponent implements OnChanges {

  @Input() experimentId: number;
  textToPush: string = "";
  experiment: Experiment = <Experiment>{};
  trackers: Tracker[];

  constructor(private repository: RepositoryService,
    private pushMessagingService: PushMessagingService,
    private toastr: ToastrService) {
  }

  ngOnChanges() {
    this.getTrackersInfo();
  }

  pushMessage() {
    this.repository.getExperiment(this.experimentId).subscribe(r => {
      this.pushMessagingService.sendMessageToExperiment(r, this.textToPush, true).subscribe(() => {
        this.toastr.success("Notification successfully pushed to participants!");
        this.textToPush = "";
      });
    });
  }

  getTrackersInfo() {
    if (!this.experimentId) {
      this.trackers = [];
      return;
    }

    this.repository.getTrackers(this.experimentId).subscribe(r => {
      this.trackers = r;     
    });
  }
}
