import { Role } from "../../models/role";

export class SessionInfo {
  userName: string;
  token: string;
  roles: Role[];

  constructor(userName: string, token: string, roles: Role[]) {
    this.userName = userName;
    this.token = token;
    this.roles = roles;
  }
}
