import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { SessionService } from '../services/session/session.service';

@Directive({
  selector: '[needsAuthenticated]'
})
export class NeedsAuthenticatedDirective implements OnDestroy  {

  private onChangeFn = null;

  constructor(private sessionService: SessionService, private el: ElementRef) {
    this.setVisibility(sessionService, el);
    this.onChangeFn = () => this.setVisibility(sessionService, el);
    sessionService.registerOnSessionChange(this.onChangeFn);
  }

  private setVisibility(sessionService: SessionService, el: ElementRef) {
    if (sessionService.getSession() == null || !sessionService.getSession().token ||
      sessionService.getSession().token == "")
      el.nativeElement.style.visibility = 'hidden';
    else
      el.nativeElement.style.visibility = 'inherit';
  }

  ngOnDestroy() {
    this.sessionService.unregisterOnSessionChange(this.onChangeFn);
  }
}
