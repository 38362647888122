import { Occurence } from "./occurence";

export class ExperimentSurvey {
  id: number
  code: string;
  name: string;
  json: string;
  firstDt: number;
  occurence: Occurence;
  validityS: number;
  includeLocation: boolean;
  locationMaxAgeMs: number;
  alwaysAccessible: boolean;

  experimentId: number;
  // Client-only property
  numberOfResponses: number;

  constructor(id: number, code: string, name: string,
    json: string, experimentId: number, numberOfResponses: number,
    firstDt: number, occurence: Occurence, validityS: number) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.json = json;
    this.experimentId = experimentId;
    this.numberOfResponses = numberOfResponses;
    this.firstDt = firstDt;
    this.occurence = occurence;
    this.validityS = validityS;
    this.includeLocation = false;
    this.locationMaxAgeMs = 0.0;
    this.alwaysAccessible = false;
  }
}
