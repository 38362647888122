import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ExperimentSurvey } from "../../models/experimentSurvey";
import { RepositoryService } from "../../services/repository/repository.service";
import { Experiment } from "../../models/experiment";
import AppSettings from "../../app-settings";
import DateTimeUtils from '../../utilities/datetimeutils';

@Component({
  selector: 'app-experiment-survey-manage-form',
  templateUrl: './experiment-survey-manage-form.component.html',
  styleUrls: ['./experiment-survey-manage-form.component.css']
})
export class ExperimentSurveyManageFormComponent implements OnInit {

  public surveyForm: FormGroup;

  model: ExperimentSurvey = <ExperimentSurvey>{};
  helpmodel = { firstDtAsDate: null, firstDtAsDate_time: null };

  experiment: Experiment = <Experiment>{};
  websurveyUrl: string;
  firstCronExpression: string = ""; // Just init value, since cron-editor does not like empty expressions.

  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit() {
    this.surveyForm = this.fb.group({
      code: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      firstDt: new FormControl(''),
      occurence: new FormControl(''),
      validityS: new FormControl(''),
      firstDt_time: new FormControl(''),
      isinformedconsent: new FormControl(''),
      includeLocation: new FormControl(''),
      locationMaxAgeMs: new FormControl(''),
      modeRadio1: new FormControl(''),
      modeRadio2: new FormControl('')
    });

    let experimentId = this.route.snapshot.params['experimentId'];
    let surveyId = this.route.snapshot.params['surveyId'];

    this.repository.getExperimentSurvey(experimentId, surveyId).subscribe(r => {
      this.model = r;
      this.tryInit();
    });
    this.repository.getExperiment(experimentId).subscribe(r => {
      this.experiment = r;
      this.tryInit();
    })

    this.surveyForm.valueChanges.subscribe(r => {
      this.scheduleUpdateSurvey();
      this.trySetWebSurveyUrl();
    });
  }

  private tryInit() {
    if (!this.model || !this.experiment)
      return;

    if (this.model.firstDt != null) {
      var d = DateTimeUtils.UnixTimeToDateTime(this.model.firstDt * 1000, this.experiment.timezone);
      this.helpmodel.firstDtAsDate_time = d;
      this.helpmodel.firstDtAsDate = new Date(d.getFullYear(), d.getMonth(), d.getDate()); 
    }
    this.trySetWebSurveyUrl();
  }

  private trySetWebSurveyUrl() {
    if (!this.model || !this.experiment)
      return;
    this.websurveyUrl = AppSettings.websurveyUrl.replace("{experiment}", this.experiment.code);
    this.websurveyUrl = this.websurveyUrl.replace("{survey}", this.model.code);
  }

  private updateTs = -1;
  scheduleUpdateSurvey() {
    this.updateTs = (new Date()).getTime();
    let localTs = this.updateTs;

    window.setTimeout(() => {
      if (localTs != this.updateTs)
        return;
      this.updateSurvey();
    }, 300);
  }

  private updateSurvey() {
    if (!this.model || !this.model.id)
      return;

    if (this.helpmodel.firstDtAsDate != null && this.helpmodel.firstDtAsDate_time != null) {
      var dt = new Date(this.helpmodel.firstDtAsDate.getTime());
      var time = this.helpmodel.firstDtAsDate_time;
      dt.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

      this.model.firstDt = DateTimeUtils.DateTimeToUnixTime(dt, this.experiment.timezone) / 1000;
    } else {
      this.model.firstDt = null;
    }
    this.repository.updateExperimentSurvey(this.model.experimentId, this.model).subscribe(
      r => { });
  }
}
