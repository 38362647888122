import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionInfo } from "../session/sessionInfo";
import { SessionService } from "../session/session.service";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers.set('secret', this.sessionService.getSession().token) });

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Don't do anything
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.status == 403) {
          this.sessionService.storeSession(null);
          this.router.navigateByUrl('login');
          alert("You are not authorized to access this page.");
          return Observable.empty();
        } else {
          let msg: string = "HTTP response error " + error.status + ": " + error.message;
          console.log(msg);
          return Observable.throw(error);
        }
      }));
  }
}
