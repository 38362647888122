import { Role } from "./role";

export class Account {
  id: number;
  userName: string;
  secret: string;
  experiments: number[];
  roles: Role[]; 

  constructor(id: number, userName: string,
    secret: string, experiments: number[],
    roles: Role[]) {
    this.id = id;
    this.userName = userName;
    this.secret = secret;
    this.experiments = experiments;
    this.roles = roles;
  }
}
