import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-changepassword-form',
  templateUrl: './changepassword-form.component.html',
  styleUrls: ['./changepassword-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {

  public changePasswordForm: FormGroup;

  errorMessage: string = null;

  model: any = { userName: "", oldPassword: "", newPassword1: "", newPassword2: ""};

  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private router: Router,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      userName: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword1: new FormControl('', Validators.required),
      newPassword2: new FormControl('', Validators.required)
    }, {
      validator: this.passwordMatchValidator
      });

    this.changePasswordForm.valueChanges.subscribe(r => {
      this.errorMessage = null;
    });
  }

  private passwordMatchValidator(form: FormGroup) {
    if (form.get('newPassword1').value !== form.get('newPassword2').value)
      return { passwordsDoNotMatch: true };
    return null;
  }

  changePassword() {
    if (!this.changePasswordForm.valid)
      return;

    this.repository.changePassword(this.model.userName,
      this.model.oldPassword, this.model.newPassword1).subscribe(r => {
        this.toastr.success("Password successfully changed.");
        this.router.navigateByUrl('login');
      }, (err: any) => {
        this.errorMessage = err.error.message;
      });
  }
}
