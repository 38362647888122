import { Pipe, PipeTransform } from '@angular/core';
import { Occurence } from '../../models/occurence';
import { UserEventsService } from '../userevents/userevents.service';

@Pipe({
  name: 'userEventExpression'
})
export class UserEventExpressionPipe implements PipeTransform {

  constructor(private userEventsService: UserEventsService) { }

  transform(value: any, experimentId: number): any {
    if (!value)
      return Promise.resolve("-");
    return this.userEventsService.getEventChoiceFromOccurence(experimentId,
      <Occurence>value).then(uec => {
        if (uec)
          return uec.name;
        return "-";
      });
  }
}
