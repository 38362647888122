import {
  Component, Input, Output, EventEmitter,
  OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import * as SurveyEditor from 'surveyjs-editor';

@Component({
    selector: 'survey-editor',
    template: `<div id="surveyEditorContainer" style="height:100%;overscroll:auto;" ></div>`,
})
export class SurveyEditorComponent implements OnInit, OnChanges  {
    editor: SurveyEditor.SurveyEditor;
    @Input() json: any;
    @Output() jsonChange = new EventEmitter<String>();
    @Output() onUpdate = new EventEmitter<boolean>();

    ngOnInit() {
        let editorOptions = {
          showTestSurveyTab: true,
          showEmbeddedSurveyTab: false,
          generateValidJSON: true
        };

        this.editor = new SurveyEditor.SurveyEditor('surveyEditorContainer', editorOptions);
        this.editor.text = this.json;
        this.editor.isAutoSave = true;
        this.editor.saveSurveyFunc = this.updateModel;
    }

    // We only use the input for initialisation.
    ngOnChanges(changes: SimpleChanges) {
      if (this.editor && changes.json && !changes.json.previousValue)
        this.editor.text = this.json;
    }

    updateModel = () => {
      var survey_obj_temp = null;
      try {
        survey_obj_temp = JSON.parse(this.editor.text);
      }
      catch (err) {
        // The editor text might have a structure without quotes for
        // property names (non-json). To handle this, evaluate as
        // dynamic code. Very ugly, though it works like a charm :-D.
        var dmy_temp = null;
        var objEvalString = "survey_obj_temp = " + this.editor.text + ";";
        eval(objEvalString);
      }
      this.jsonChange.emit(JSON.stringify(survey_obj_temp));
      this.onUpdate.emit(true);
    }
}
