import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { RepositoryService } from "../../services/repository/repository.service";
import { Experiment } from "../../models/experiment";
import { Notification } from "../../models/notification";

@Component({
  selector: 'app-experiment-notification-manage-form',
  templateUrl: './experiment-notification-manage-form.component.html',
  styleUrls: ['./experiment-notification-manage-form.component.css']
})
export class ExperimentNotificationManageFormComponent implements OnInit {

  public notificationForm: FormGroup;

  model: Notification = <Notification>{};

  experiment: Experiment = <Experiment>{};

  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit() {
    this.notificationForm = this.fb.group({
      message: new FormControl('', Validators.required),
      occurence: new FormControl('')
    });

    let experimentId = this.route.snapshot.params['experimentId'];
    let notificationId = this.route.snapshot.params['notificationId'];

    this.repository.getNotification(experimentId, notificationId).subscribe(r => {
      this.model = r;
    });

    this.repository.getExperiment(experimentId).subscribe(r => {
      this.experiment = r;
    })

    this.notificationForm.valueChanges.subscribe(r => {
      this.scheduleUpdateNotification();
    });
  }

  private updateTs = -1;

  scheduleUpdateNotification() {
    this.updateTs = (new Date()).getTime();
    let localTs = this.updateTs;

    window.setTimeout(() => {
      if (localTs != this.updateTs)
        return;
      this.updateNotification();
    }, 300);
  }

  private updateNotification() {
    if (!this.model || !this.model.id)
      return;

    this.repository.updateNotification(this.model.experimentId, this.model).subscribe(
      r => { });
  }
}
