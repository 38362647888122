import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

import { RepositoryService } from '../../services/repository/repository.service';
import { Notification } from "../../models/notification";
import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-experiment-notifications-list',
  templateUrl: './experiment-notifications-list.component.html',
  styleUrls: ['./experiment-notifications-list.component.css']
})
export class ExperimentNotificationsListComponent implements OnInit, OnChanges {

  @Input() experimentId: number;
  @Input() newNotificationSubject: Subject<Notification>;

  public notifications: Notification[];

  constructor(private repositoryService: RepositoryService) { }

  ngOnInit() {
    this.refreshData();
    this.newNotificationSubject.subscribe(r => {
      if (this.experimentId == r.experimentId) {
        this.notifications.push(r);
        this.sortData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.experimentId.currentValue != changes.experimentId.previousValue)
      this.refreshData();
  }

  refreshData() {
    if (!this.experimentId) {
      this.notifications = [];
      return;
    }
    this.repositoryService.getNotifications(this.experimentId).subscribe(res => {
      this.notifications = res;
      this.sortData();
    });
  }

  sortData() {
    this.notifications.sort((a, b) => a.id > b.id ? -1 : 1);
  }

  deleteNotification(notificationId) {
    if (window.confirm('Are sure you want to delete this item?')) {
      this.repositoryService.deleteNotification(this.experimentId, notificationId).subscribe(res => {
        var idx = -1;
        for (var i = 0; i < this.notifications.length; i++)
          if (this.notifications[i].id == notificationId)
            idx = i;
        this.notifications.splice(idx, 1);
      });
    }
  }
}
