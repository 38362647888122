import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

import { RepositoryService } from '../../services/repository/repository.service';
import { DataSource } from "../../models/dataSource";
import { Subject } from "rxjs/Rx";
import { NgxSmartModalService } from 'ngx-smart-modal';
import DateTimeUtils from '../../utilities/datetimeutils';
import Utils from '../../utilities/utils';
import { Experiment } from '../../models/experiment';

@Component({
  selector: 'app-experiment-datasources-list',
  templateUrl: './experiment-datasources-list.component.html',
  styleUrls: ['./experiment-datasources-list.component.css']
})
export class DataSourcesListComponent implements OnInit, OnChanges {

  @Input() experiment: Experiment;
  @Input() newDataSourceSubject: Subject<DataSource>;

  public dataSources: DataSource[];
  public sourceToDownload: DataSource = <DataSource>{};
  public downloadFilter: DownloadFilter;

  constructor(private repositoryService: RepositoryService,
    private ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.refreshData();
    this.newDataSourceSubject.subscribe(r => {
      if (this.experiment.id == r.experimentId) {
        this.dataSources.push(r);
        this.sortData();
      }
    });

    // Set download filter.
    var fromDateTime: Date = new Date();
    fromDateTime.setHours(0);
    fromDateTime.setMinutes(0);
    fromDateTime.setSeconds(0);
    fromDateTime.setMilliseconds(0);
    this.downloadFilter = new DownloadFilter();
    this.downloadFilter.downloadFromDt_date = fromDateTime;
    this.downloadFilter.downloadFromDt_time = (new Date(fromDateTime.getTime()));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.experiment.currentValue != changes.experiment.previousValue)
      this.refreshData();
  }

  refreshData() {
    if (!this.experiment.id) {
      this.dataSources = [];
      return;
    }
    this.repositoryService.getDataSources(this.experiment.id).subscribe(res => {
      this.dataSources = res;
      this.sortData();
    });
  }

  sortData() {
    this.dataSources.sort((a, b) => a.id > b.id ? -1 : 1);
  }

  deleteDataSource(dataSourceId) {
    if (window.confirm('Are sure you want to delete this item?')) {
      this.repositoryService.deleteDataSource(this.experiment.id, dataSourceId).subscribe(res => {
        var idx = -1;
        for (var i = 0; i < this.dataSources.length; i++)
          if (this.dataSources[i].id == dataSourceId)
            idx = i;
        this.dataSources.splice(idx, 1);
      });
    }
  }

  openDownloadModal(source: DataSource) {
    this.sourceToDownload = source;
    this.ngxSmartModalService.getModal('downloadModal').open();
  }

  downloadAsCsv() {
    let fromTime = this.downloadFilter.getFilterFromUnixTime(this.experiment.timezone);
    let tillTime = this.downloadFilter.getFilterTillUnixTime(this.experiment.timezone);

    this.repositoryService.getDataValues_asCSV(this.experiment.id, this.sourceToDownload.id, fromTime, tillTime).subscribe(blob => {
      Utils.downloadAsFile(blob, "datavalues_" + this.sourceToDownload.id + "_" + DateTimeUtils.NowUnixTime() + ".csv");
    });
  }

  downloadAsGeoJson() {
    let fromTime = this.downloadFilter.getFilterFromUnixTime(this.experiment.timezone);
    let tillTime = this.downloadFilter.getFilterTillUnixTime(this.experiment.timezone);

    this.repositoryService.getDataValues_asGeoJson(this.experiment.id, this.sourceToDownload.id, fromTime, tillTime).subscribe(blob => {
      Utils.downloadAsFile(blob, "trajectories_" + this.sourceToDownload.id + "_" + DateTimeUtils.NowUnixTime() + ".geojson");
    });
  }

  onDeleteResults(dataSourceId) {
    if (window.confirm("Are you sure you want to delete all data?")) {
      this.repositoryService.deleteDataValues(this.experiment.id, dataSourceId).subscribe(res => {
        this.refreshData();
      });
    }
  }
}

class DownloadFilter {
  public downloadFromDt_date: Date;
  public downloadFromDt_time: Date;
  public downloadTillDt_date: Date;
  public downloadTillDt_time: Date;

  public getFilterFromUnixTime(timezone: string): number {
    if (this.downloadFromDt_date == null)
      return 0;

    var dt = new Date(this.downloadFromDt_date.getTime());
    if (this.downloadFromDt_time != null) {
      var time = this.downloadFromDt_time;
      dt.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
    }
    return DateTimeUtils.DateTimeToUnixTime(dt, timezone);
  }

  public getFilterTillUnixTime(timezone: string): number {
    if (this.downloadTillDt_date == null)
      return 0;

    var dt = new Date(this.downloadTillDt_date.getTime());
    if (this.downloadTillDt_time != null) {
      var time = this.downloadTillDt_time;
      dt.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
    }
    return DateTimeUtils.DateTimeToUnixTime(dt, timezone);
  }
}
