import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../services/repository/repository.service';
import { Account } from "../models/account";
import * as moment from 'moment';
import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-accountspage',
  templateUrl: './accountspage.component.html',
  styleUrls: ['./accountspage.component.css']
})
export class AccountsPageComponent implements OnInit {

  constructor(private repository: RepositoryService) { }

  newAccountSubject:Subject<Account> = new Subject();

  ngOnInit() {
  }

  createAccount() {
    var userName = "User" + moment().valueOf().toString();
    let account = new Account(-1, userName, "thispasswordshouldbeupdated", [], []);
    this.repository.createAccount(account).subscribe(
      r => { this.newAccountSubject.next(r); }
    );
  }
}
