import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Experiment } from '../../models/experiment';
import { RepositoryService } from '../../services/repository/repository.service';
import { ActivatedRoute } from '@angular/router';
import { ExperimentSurvey } from "../../models/experimentSurvey";
import { Notification } from "../../models/notification";
import * as moment from 'moment-timezone';
import { Subject } from "rxjs/Rx";
import { DataSource } from "../../models/dataSource";
import DateTimeUtils from '../../utilities/datetimeutils';
import { Occurence } from '../../models/occurence';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-experiment-manage-form',
  templateUrl: './experiment-manage-form.component.html',
  styleUrls: ['./experiment-manage-form.component.css']
})
export class ExperimentManageFormComponent implements OnInit, OnDestroy {

  public experimentForm: FormGroup;

  public newExperimentSurveySubject: Subject<ExperimentSurvey> = new Subject();
  public newNotificationSubject: Subject<Notification> = new Subject();
  public newDataSourceSubject: Subject<DataSource> = new Subject();

  public timezoneChangeAlertVisible: boolean = false;

  public allTimezones: string[];

  constructor(private repository: RepositoryService,
    private fb: FormBuilder, private route: ActivatedRoute,
    private toastr: ToastrService) {

    this.allTimezones = moment.tz.names();
  }

  model: Experiment = <Experiment>{};
  helpmodel = { tsStartAsDate: new Date(), tsEndAsDate: new Date() };

  trackingCronExpression = "";

  ngOnInit() {
    this.experimentForm = this.fb.group({
      code: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      tsStartAsDate: new FormControl(''),
      tsEndAsDate: new FormControl(''),
      welcomeText: new FormControl(''),
      helpText: new FormControl(''),
      timezone: new FormControl(''),
      isdebug: new FormControl(''),
      isdiscoverable: new FormControl('')
    });

    let id = this.route.snapshot.params['id'];
    this.repository.getExperiment(id).subscribe(r => {
      this.model = r;
      this.helpmodel.tsStartAsDate = DateTimeUtils.UnixTimeToDate(r.tsStart * 1000, this.model.timezone);
      this.helpmodel.tsEndAsDate = DateTimeUtils.UnixTimeToDate(r.tsEnd * 1000, this.model.timezone);

      this.experimentForm.valueChanges.subscribe(r => {
        this.scheduleUpdateExperiment();
      });
    });
  }

  ngOnDestroy() {
    this.updateExperiment();
  }

  createSurvey() {
    let tsNow: moment.Moment = moment();
    var code = "Survey " + tsNow.valueOf().toString();
    let survey = new ExperimentSurvey(-1, code, code, "{}", this.model.id, 0, null, null, null);
    this.repository.createExperimentSurvey(this.model.id, survey).subscribe(r => {
      this.newExperimentSurveySubject.next(r);
    });
  }

  createNotification() {
    let notification = new Notification(-1, "This is a notification!", this.model.id, null);
    this.repository.createNotification(this.model.id, notification).subscribe(r => {
      this.newNotificationSubject.next(r);
    });
  }

  createDataSource() {
    var type = "gps";
    let cron = "0 0/5 0 ? * * *"; // every 5 minutes
    let occurence = new Occurence(null, null, null, cron);
    let dataSource = new DataSource(-1, this.model.id, type, occurence, null, null);
    this.repository.createDataSource(this.model.id, dataSource).subscribe(r => {
      this.newDataSourceSubject.next(r);
    });
  }

  private updateTs = -1;
  private scheduleUpdateExperiment() {
    this.updateTs = (new Date()).getTime();
    let localTs = this.updateTs;

    window.setTimeout(() => {
      if (localTs != this.updateTs)
        return;
      this.updateExperiment();
    }, 300);
  }

  private updateExperiment() {
    if (!this.experimentForm.valid)
      return;

    this.model.tsStart = this.helpmodel.tsStartAsDate != null ? DateTimeUtils.DateToUnixTime(this.helpmodel.tsStartAsDate, this.model.timezone) * 1.0 / 1000 : null;
    this.model.tsEnd = this.helpmodel.tsEndAsDate != null ? DateTimeUtils.DateToUnixTime(this.helpmodel.tsEndAsDate, this.model.timezone) * 1.0 / 1000 : null;
    return this.repository.updateExperiment(this.model);
  }

  public onTimezoneChanged() {
    this.timezoneChangeAlertVisible = true;
  }

  public cleanExperiment() {
    if (window.confirm("This will delete all data that has been collected in the experiment so far. Are you sure you want to continue?")) {
      return this.repository.cleanExperimentData(this.model).subscribe(r => {
        this.toastr.success("Experiment data has been deleted!");
      })
    }
  }
}
