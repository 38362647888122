import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../services/repository/repository.service';
import { Experiment } from "../models/experiment";
import * as moment from 'moment';
import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-experimentspage',
  templateUrl: './experimentspage.component.html',
  styleUrls: ['./experimentspage.component.css']
})
export class ExperimentspageComponent implements OnInit {

  constructor(private repository: RepositoryService) { }

  newExperimentSubject:Subject<Experiment> = new Subject();

  ngOnInit() {
  }

  createExperiment() {
    let tsNow: moment.Moment = moment();
    var code = "Experiment " + tsNow.valueOf().toString();
    let start = moment(tsNow).add('week', 1);
    let end = moment(tsNow).add('week', 2);
    let exp = new Experiment(-1, code, code, start.valueOf() / 1000, end.valueOf() / 1000, "0 * * ? * *", "Europe/Amsterdam", false, false, "","");
    this.repository.createExperiment(exp).subscribe(
      r => { this.newExperimentSubject.next(r); }
    );
  }
}
