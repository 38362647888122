import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { Experiment } from '../../models/experiment';
import * as moment from 'moment';
import { Subject } from "rxjs/Rx";

@Component({
  selector: 'app-experiments-list',
  templateUrl: './experiments-list.component.html',
  styleUrls: ['./experiments-list.component.css']
})
export class ExperimentsListComponent implements OnInit, OnChanges {

  @Input() newExperimentSubject: Subject<Experiment>;

  public experiments: Experiment[];

  public tsNow: number = moment().valueOf() / 1000;

  constructor(private repositoryService: RepositoryService) { }

  ngOnChanges() {
  }

  ngOnInit() {
    this.retrieveData();
    this.newExperimentSubject.subscribe(r => {
      this.experiments.push(r);
      this.sortData();
    });
  }

  retrieveData() {
    this.repositoryService.getExperiments().subscribe(res => {
      this.experiments = res;
      this.sortData();
    });
  }

  sortData() {
    this.experiments.sort((a, b) => a.tsStart > b.tsStart ? -1 : 1);
  }

  deleteExperiment(experimentId: number) {
    if (window.confirm('Are sure you want to delete this item?')) {
      this.repositoryService.deleteExperiment(experimentId).subscribe(res => {
        var idx = -1;
        for (var i = 0; i < this.experiments.length; i++)
          if (this.experiments[i].id == experimentId)
            idx = i;
        this.experiments.splice(idx, 1);
      });
    }
  }
}
